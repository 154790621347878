import React from "react";
import styled from "@emotion/styled";
// utils
import { Colors } from "../utils/colors";

export const FlowWrap = (props) => {
  return (
    <SWrap>
      <span data-flow={props.flow01}>入力</span>
      <span data-flow={props.flow02}>確認</span>
      <span data-flow={props.flow03}>完了</span>
    </SWrap>
  );
};

const SWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  span {
    padding: 0.5rem 2rem;
    line-height: 1;
    border: 1px solid ${Colors.primary};
    font-size: 0.9rem;
    font-weight: 500;
    &[data-flow="true"] {
      background-color: ${Colors.primary};
      color: ${Colors.light};
    }
    &[data-flow="false"] {
      background-color: ${Colors.light};
      color: ${Colors.primary};
    }
    &:nth-of-type(1) {
      border-radius: 8px 0 0 8px;
    }
    &:nth-of-type(2) {
      border-right: none;
      border-left: none;
    }
    &:nth-of-type(3) {
      border-radius: 0 8px 8px 0;
    }
  }
`;
