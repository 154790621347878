import React from "react";
import styled from "@emotion/styled";
// utils
import { Colors } from "../utils/colors";

export const ProgressWrap = (props) => {
  return (
    <SWrap>
      <div data-progress={props.progress01}></div>
      <div data-progress={props.progress02}></div>
      <div data-progress={props.progress03}></div>
      <div data-progress={props.progress04}></div>
      <div data-progress={props.progress05}></div>
    </SWrap>
  );
};

const SWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  div {
    display: inline-flex;
    align-items: center;
    &::before {
      content: "";
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 100vh;
      font-size: 0.9rem;
      font-weight: 500;
    }
    &::after {
      content: "";
      display: inline-flex;
      width: 4rem;
      height: 2px;
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    &[data-progress="true"] {
      &::before {
        background-color: ${Colors.primary};
        border: 2px solid ${Colors.primary};
      }
      &::after {
        background-color: ${Colors.primary};
        animation-name: pageMove;
        animation-fill-mode: backwards;
        animation-duration: 0.3s;
      }
    }
    &[data-progress="false"] {
      &::before {
        background-color: ${Colors.light};
        border: 2px solid ${Colors.gray};
      }
      &::after {
        background-color: ${Colors.gray};
      }
    }
  }

  @keyframes pageMove {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;
