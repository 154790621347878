import React from "react";
import "../../styles/global.css";
// components
import { Seo } from "../seo/seo";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";

export const Layout = (props) => {
  return (
    <>
      <Seo />
      <Header />
      <main>{props.children}</main>
      <Footer />
    </>
  );
};
