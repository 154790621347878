import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// components
import { Layout } from "../components/layout/Layout";
import { FlowWrap } from "../components/wrapper/flowWrap";
import { ProgressWrap } from "../components/wrapper/progressWrap";
// utils
import { Colors } from "../components/utils/colors";

const ThanksPage = () => {
  return (
    <Layout>
      <SSection>
        <SSlide className="slide">
          <FlowWrap flow01="false" flow02="false" flow03="true" />
          <ProgressWrap
            progress01="true"
            progress02="true"
            progress03="true"
            progress04="true"
            progress05="true"
          />
          <SForm>
            <h3>お問い合わせありがとうございます。</h3>
            <p>
              内容の確認後、XX営業日以内に担当者よりご連絡させていただきます。
            </p>
            <Link to="/">トップページに戻る</Link>
          </SForm>
        </SSlide>
      </SSection>
    </Layout>
  );
};

export default ThanksPage;

const SSection = styled.section`
  width: calc(100% - 4rem);
  min-height: calc(100% - 2rem);
  margin: auto;
  padding: 2rem;
  background-color: ${Colors.light};
  border-radius: 8px;
  @media screen and (max-width: 599px) {
    width: calc(100% - 1rem);
    height: calc(100% - 2rem);
    padding: 1rem;
  }
`;

const SSlide = styled.div`
  width: 100%;
  height: 100%;
`;

const SForm = styled.div`
  min-height: calc(100% - 64px - 64px - 3rem);
  margin-top: 3rem;
  text-align: center;
  h3 {
    color: ${Colors.primary};
  }
  p {
    margin-top: 1rem;
    color: ${Colors.dark};
    font-weight: 500;
  }
  a {
    margin-top: 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: none;
    padding: 1rem 2rem;
    min-width: 320px;
    border-radius: 8px;
    transition: 0.2s;
    border: 1px solid ${Colors.primary};
    background-color: ${Colors.primary};
    color: ${Colors.light};

    @media screen and (min-width: 600px) {
      &:hover {
        background-color: ${Colors.light};
        color: ${Colors.primary};
      }
    }
  }
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
    a {
      width: 100%;
      &:active {
        background-color: ${Colors.light};
        color: ${Colors.primary};
      }
    }
  }
`;
